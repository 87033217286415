.a-mailerActionButton {
  background-color: #294de6;
  border-radius: 30px;
  color: #fff;
  display: block;
  font-size: 15px;
  height: 60px;
  line-height: 60px;
  max-width: 280px;
  text-align: center;
}