.o-userAssignProspectListContainer {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: column;
  width: 100%;
}

.o-userAssignProspectListContainer-list {
  list-style: none;
}

.o-userAssignProspectListContainer-list-row {
  border-top: 1px solid #ddd;
}
.o-userAssignProspectListContainer-list-row > a,.o-userAssignProspectListContainer-list-row > span{
    align-items: center;
    color: #708293;
    display: flex;
    height: 70px;
  }
.o-userAssignProspectListContainer-list-row:first-child{
    border: none;
  }
.o-userAssignProspectListContainer-list-row a:hover{
    background-color: #f2f2f2;
  }

.o-userAssignProspectListContainer-list-cell {
  padding: 0 10px;
  text-align: center;
  vertical-align: middle;
}
.o-userAssignProspectListContainer-list-cell > svg{
    display: block;
  }
.o-userAssignProspectListContainer-list-cell:first-child{
    padding-left: 20px;
  }
.o-userAssignProspectListContainer-list-cell:last-child{
    margin-left: auto;
    padding-right: 30px;
    text-align: right;
  }

.o-userAssignProspectListContainer-list-nameContainer {
  align-items: center;
  display: flex;
  font-weight: bold;
  white-space: nowrap;
}

.o-userAssignProspectListContainer-list-name {
  color: #112f4b;
  font-size: 15px;
}

.o-userAssignProspectListContainer-list-imageContainer {
  height: 48px;
  padding: 9px;
  width: 48px;
}

.o-userAssignProspectListContainer-list-position {
  display: flex;
  font-size: 13px;
  justify-content: center;
  line-height: 1.3;
  white-space: nowrap;
}

.o-userAssignProspectListContainer-list-comment {
  align-items: center;
  display: flex;
  justify-content: center;
}
.o-userAssignProspectListContainer-list-comment .o-userAssignProspectListContainer-list-comment-heading{
    color: #112f4b;
    display: block;
    font-size: 16px;
  }

.o-userAssignProspectListContainer-list-comment-count {
  color: #112f4b;
  font-size: 14px;
  font-weight: bold;
  margin-left: 7px;
}

.o-userAssignProspectListContainer-list-createdDate {
  display: flex;
  font-size: 12px;
  justify-content: flex-end;
  white-space: nowrap;
}
