.o-slackConnection-description{
    background-color: #f6f8fa;
    border: solid 1px #ddd;
    border-radius: 4px;
    padding: 20px;
  }
.o-slackConnection-description__title{
      font-size: 16px;
      font-weight: bold;
    }
.o-slackConnection-description > ul{
      list-style: none;
      margin: 15px 0;
    }
.o-slackConnection-actions{
    display: flex;
    margin-top: 20px;
  }
.o-slackConnection-actions-button{
    display: flex;
    padding: 10px 20px;
  }
.o-slackConnection-actions-button + .o-slackConnection-actions-button{
      margin-left: 10px;
    }
