.o-webhookPanelList {
  list-style: none;
  margin: 0;
  padding: 0;
}
.o-webhookPanelList-item{
    align-items: center;
    border-bottom: 1px solid #ddd;
    display: flex;
    padding: 15px 0;
  }
.o-webhookPanelList-item-body{
      margin-right: auto;
    }
.o-webhookPanelList-item-name{
      font-size: 15px;
      margin-bottom: 5px;
    }
.o-webhookPanelList-item-notifications span{
        color: #708293;
        font-size: 13px;
      }
.o-webhookPanelList-item-notifications span + span::before{
            content: "、";
            display: inline-block;
            vertical-align: top;
          }
.o-webhookPanelList-item-actions{
      align-items: center;
      display: flex;
      margin-left: auto;
    }
