.p-scheduleMailer-title{
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
    font-size: 18px;
  }
.p-scheduleMailer-title b{
      font-weight: bold;
    }
.p-scheduleMailer-description{
      font-size: 16px;
      text-align: left;
      padding-top: 20px;
    }
.p-scheduleMailer-content{
    padding: 30px 0;
  }
.p-scheduleMailer-content-prospectAvatarImageContainer .a-avatarImage{
        width: 60px;
        height: 60px;
        display: block;
      }
.p-scheduleMailer-content-prospectFullName{
      color: #112f4b;
      font-size: 18px;
      font-weight: bold;
      line-height: 20px;
      margin: 0;
      padding-bottom: 15px;
    }
.p-scheduleMailer-content-position{
      color: #708293;
      font-size: 12px;
      line-height: 24px;
      padding-bottom: 15px;
    }
.p-scheduleMailer-content-detail-wrapper {
      border: solid 1px #eee;
      border-radius: 6px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.04);
}
.p-scheduleMailer-content-detail{
      background-color: #fff;
      border-collapse: separate;
      border-spacing: 0;
      overflow: hidden;
      padding: 20px;
    }
.p-scheduleMailer-content-detail-date{
        color: #ec4040;
        font-size: 16px;
        line-height: 20px;
      }
.p-scheduleMailer-content-detail-time{
        color: #708293;
        font-size: 16px;
        line-height: 20px;
        padding-top: 5px;
      }
.p-scheduleMailer-content-detail-title{
        color: #112f4b;
        font-size: 20px;
        font-weight: bold;
        line-height: 24px;
        padding-top: 10px;
      }
.p-scheduleMailer-content-detail-attenders{
        padding-top: 10px;
      }
.p-scheduleMailer-content-detail-attenders-item{
          border: 2px solid #fcfcfc;
          border-radius: 50%;
          float: left;
          height: 24px;
          margin-right: -6px;
          width: 24px;
        }
.p-scheduleMailer-content-detail-attenders-item > img{
            display: block;
            height: 100%;
            width: 100%;
          }
.p-scheduleMailer-content-detail-attenders-item > span{
            background-color: #294de6;
            border-radius: 50%;
            color: #fff;
            display: block;
            font-size: 10px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            width: 20px;
          }
.p-scheduleMailer-content-detail-description{
        color: #708293;
        font-size: 16px;
        padding-top: 20px;
      }
