.p-mailMessageMailer {
  height: auto;
}
.p-mailMessageMailer-body {
  max-width: 500px;
  padding: 15px;
  background-color: #fff;
}
.p-mailMessageMailer-body .content_container {
  padding: 10px 0;
}
.p-mailMessageMailer-body .content_link {
}
.p-mailMessageMailer-body .box_container {
  display: flex;
}
.p-mailMessageMailer-body .box_header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.p-mailMessageMailer-body .box_image {
  width: 120px;
  height: 63px;
}
.p-mailMessageMailer-body .box_title {
  flex: 1;
  display: flex;
  align-items: center;
}
.p-mailMessageMailer-body .box_title_inner {
  color: #112f4b;
  font-weight: 600;
  font-size: 14px;
}
