.p-commentMailer .o-commentMailer-title{
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
    font-size: 18px;
  }
.p-commentMailer .o-commentMailer-title b{
      font-weight: bold;
    }
.p-commentMailer .o-commentMailer-content{
    padding: 30px 0 40px;
  }
.p-commentMailer .o-commentMailer-content-avatarImageContainer{
      padding-bottom: 20px;
    }
.p-commentMailer .o-commentMailer-content-avatarImageContainer-separator{
        width: 9px;
        height: 12px;
        padding: 0 20px;
      }
.p-commentMailer .o-commentMailer-content-avatarImageContainer .a-avatarImage{
        width: 60px;
        height: 60px;
        display: block;
      }
.p-commentMailer .o-commentMailer-content-commentBody{
      font-size: 16px;
      text-align: left;
    }
