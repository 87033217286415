.m-dropdownMenu {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  min-width: 150px;
  padding: 5px 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  width: auto;
  z-index: 10;
}
.m-dropdownMenu-item{
    color: #112f4b;
    padding: 10px;
    text-decoration: none;
  }
.m-dropdownMenu-item:hover{
      background-color: #f2f2f2;
    }
