.p-noteRemindMailer .o-noteRemindMailer-title{
  font-size: 18px;
  padding-bottom: 10px;
  font-weight: bold;
}
.p-noteRemindMailer .o-noteRemindMailer-description{
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  font-size: 12px;
  color: #718096;
}
.p-noteRemindMailer .o-noteRemindMailer-title b{
  font-weight: bold;
}
.p-noteRemindMailer .o-noteRemindMailer-content{
  padding: 30px 0 0;
}
.p-noteRemindMailer .o-noteRemindMailer-content-noteRow {
}
.p-noteRemindMailer .o-noteRemindMailer-content-noteHeader {
  background-color: #f0f2fd;
  width: 120px;
  height: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.p-noteRemindMailer .o-noteRemindMailer-content-noteTitle{
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 10px;
  padding-left: 10px;
}
.p-noteRemindMailer .o-noteRemindMailer-content-noteTitle a {
  color: #1a202c;
}
