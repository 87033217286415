.t-singlePane {
  display: flex;
  flex: 1;
}

.t-singlePane-mainPane {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.t-singlePane-mainPane-main {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 40px;
  width: 720px;
}