.o-casePageArticle {
  max-width: 700px;
  margin: 0 auto;
  font-size: 16px;
}
.o-casePageArticle @media screen and (max-width: 800px){
    margin: 0 15px;
  }
.o-casePageArticle h1{
    font-size: 30px;
    margin: 15px 0;
  }
.o-casePageArticle h1 @media screen and (max-width: 800px){
      font-size: 27px;
    }
.o-casePageArticle h3{
    font-size: 16px;
    margin: 40px 0 20px;
  }
.o-casePageArticle p{
    line-height: 1.8;
    font-size: 16px;
    margin: 15px 0;
  }
.o-casePageArticle ul{
    padding-left: 1em;
  }
.o-casePageArticle-header{
    align-items: center;
    margin-bottom: 30px;
    display: flex;
  }
.o-casePageArticle-header-date{
      color: #708293;
      font-size: 14px;
      margin-right: 10px;
    }
.o-casePageArticle-image-wrapper{
    margin: 30px 0;
  }
.o-casePageArticle-image-wrapper img{
      width: 100%;
    }
.o-casePageArticle-image-wrapper span{
      font-size: 14px;
      color: #41596f;
    }
.o-casePageArticle-section-speaker{
      font-weight: bold;
      margin-right: 5px;
    }
.o-casePageArticle-summary{
    background: #f6f8fa;
    padding: 5px 20px;
    margin: 30px 0;
  }
.o-casePageArticle-summary section{
      padding: 15px 0;
      display: flex;
      align-items: flex-start;
    }
.o-casePageArticle-summary section + section{
        border-top: #ddd 1px solid;
      }
.o-casePageArticle-summary section h2{
        font-size: 14px;
        color: #294de6;
        width: 30%;
        margin-right: 20px;
        flex-shrink: 0;
      }
.o-casePageArticle-summary section p{
        margin: 0;
      }
.o-casePageArticle-interviewees{
    margin: 30px 0;
  }
.o-casePageArticle-interviewees p{
      margin: 0 0 15px;
    }
.o-casePageArticle-interviewees-intervieweeImage img{
        width: 100%;
      }
.o-casePageArticle-interviewees-intervieweeRole{
      font-weight: bold;
    }
.o-casePageArticle-interviewees-intervieweeRole span{
        margin-left: 10px;
      }
