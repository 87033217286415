.o-statusPanelList-heading{
    color: #708293;
    font-size: 20px;
    font-weight: bold;
  }
.o-statusPanelList-itemContainer{
    display: flex;
    margin-top: 15px;
  }
.o-statusPanelList-itemContainer-item{
      text-align: center;
      border: 1px solid #ddd;
      background-color: #fff;
      border-radius: 6px;
      padding: 40px 30px;
    }
.o-statusPanelList-itemContainer-item + .o-statusPanelList-itemContainer-item{
        margin-left: 20px;
      }
.o-statusPanelList-itemContainer-item-heading{
        color: #708293;
        font-size: 14px;
        font-weight: bold;
      }
.o-statusPanelList-itemContainer-item-count{
        font-weight: bold;
        font-size: 60px;
      }
