.p-terms {
  padding: 50px;
}

.p-terms-main {
  max-width: 1000px;
  margin: 0 auto;
  text-align: justify;
}
.p-terms-main h1{
    font-size: 32px;
    margin: 20px 0 15px;
  }
.p-terms-main h2{
    font-size: 22px;
    margin: 15px 0 10px;
  }
.p-terms-main li{
    line-height: 1.7;
    margin: 2.5px 0;
  }
.p-terms-main p{
    text-indent: 1em;
    line-height: 1.7;
  }
.p-terms-main li > p{
    display: inline;
  }
.p-terms-main > ol,.p-terms-main > ul{
    margin: 10px 0 10px 20px;
  }
.p-terms-main ol ol > li{
    padding-left: 1.5em;
    text-indent: -1.5em;
    list-style-type: none;
    list-style-position: inside;
    counter-increment: cnt;
  }
.p-terms-main ol ol > li::before{
      display: marker;
      content: "(" counter(cnt) ") ";
    }
.p-terms-main ol ol ol > li{
  padding-left: 1.5em;
  text-indent: -1.5em;
  list-style-type: none;
  list-style-position: inside;
  counter-increment: cnt2;
}
.p-terms-main ol ol ol > li::before{
  content: "(" counter(cnt2) ") ";
}
.p-terms-main ol ul > li{
    padding-left: 1.5em;
    list-style: none;
  }
.p-terms-main .history{
    margin-top: 40px;
    margin-left: 0;
    list-style: none;
  }
