.m-prospectJobChangeIntentionPanel {
  display: flex;
  flex-direction: column;
}
.m-prospectJobChangeIntentionPanel-heading{
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    color: #708293;
  }
.m-prospectJobChangeIntentionPanel-buttonContainer{
    width: 100%;
    margin-top: 15px;
  }
