.m-prospectProfilePanel {
  display: flex;
  flex-direction: column;
}
.m-prospectProfilePanel-heading{
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    color: #708293;
  }
.m-prospectProfilePanel-profile{
    margin-top: 15px;
  }
.m-prospectProfilePanel-profile.is-empty{
      color: #708293;
    }
.m-prospectProfilePanel-profile + .m-prospectProfilePanel-heading{
      margin-top: 30px;
    }
.m-prospectProfilePanel-email{
    color: #294de6;
  }
.m-prospectProfilePanel-urlList{
    list-style: none;
    padding: 0;
    margin: 0;
  }
.m-prospectProfilePanel-urlList-item{
      padding-top: 10px;
      margin-top: 10px;
    }
.m-prospectProfilePanel-urlList-item + .m-prospectProfilePanel-urlList-item{
        border-top: 1px solid #ddd;
      }
