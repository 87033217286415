.o-prospectCustomPropertyPanelListNavigation {
  align-items: center;
  display: flex;
}

.o-prospectCustomPropertyPanelListNavigation-title {
  font-size: 24px;
}

.o-prospectCustomPropertyPanelListNavigation-prospectCustomPropertyCreateButtonContainer {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.o-prospectCustomPropertyPanelListNavigation-archiveLink {
  color: #708293;
  font-size: 14px;
  text-decoration: underline;
  margin-right: 20px;
}
.o-prospectCustomPropertyPanelListNavigation-archiveLink:hover{
    opacity: 0.8;
  }

.o-prospectCustomPropertyPanelListNavigation-paginationContainer {
  margin: 20px 0;
}
.o-prospectCustomPropertyPanelListNavigation-paginationContainer .m-pagenation{
    font-size: 16px;
  }
