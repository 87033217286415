.o-globalHeader {
  background-color: #F7FAFC;
  display: flex;
  padding: 15px 20px;
}
.o-globalHeader-notificationButtonContainer{
    margin-left: auto;
    z-index: 1;
  }
.o-globalHeader-userMenuButtonContainer{
    margin-left: 30px;
  }
