.a-whiteButton {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #112f4b;
  font-weight: bold;
  padding: 13px 20px;
  text-decoration: none;
}
.a-whiteButton:hover{
    background-color: #f2f2f2;
  }
.a-whiteButton:disabled{
    background-color: #e6e6e6;
    cursor: not-allowed;
  }
