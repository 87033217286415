.o-prospectPanelListNavigation {
  align-items: center;
  display: flex;
}
.o-prospectPanelListNavigation-title{
    font-size: 24px;
  }
.o-prospectPanelListNavigation-prospectCreateButtonContainer{
    margin-left: auto;
  }
