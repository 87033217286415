.p-usersUpsertResultMailer .o-usersUpsertResultMailer-title{
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  font-size: 18px;
  font-weight: bold;
}
.o-usersUpsertResultMailer-iconContainer{
  padding-top: 30px;
  text-align: center;
}
.p-usersUpsertResultMailer .o-usersUpsertResultMailer-description{
  padding: 20px 0 30px;
  font-size: 14px;
  text-align: center;
}