.o-form-section + .o-form-section {
  margin-top: 25px;
}

.o-form-section + .o-form-submitSection {
  margin-top: 40px;
}

.o-form-section-formGroup + .o-form-section-formGroup {
  margin-top: 20px;
}

.o-form-section-formGroup-labelContainer label {
  font-weight: bold;
  color: #708293;
}

.o-form-section-formGroup-required-indicator {
  margin-left: 5px;
  color: red;
}

.o-form-section-formGroup-inputContainer {
  margin-top: 5px;
  position: relative;
}

.o-form-section-formGroup-inputContainer input[type="password"], .o-form-section-formGroup-inputContainer input[type="number"], .o-form-section-formGroup-inputContainer input[type="text"], .o-form-section-formGroup-inputContainer input[type="email"], .o-form-section-formGroup-inputContainer input[type="url"] {
  font-size: 15px;
}

.o-form-section-formGroup-inputContainer input[type="password"]::placeholder, .o-form-section-formGroup-inputContainer input[type="number"]::placeholder, .o-form-section-formGroup-inputContainer input[type="text"]::placeholder, .o-form-section-formGroup-inputContainer input[type="email"]::placeholder, .o-form-section-formGroup-inputContainer input[type="url"]::placeholder {
  color: #a0acb7;
}

.o-form-section-formGroup-inputContainer select {
  font-size: 15px;
}

.o-form-section-formGroup-inputContainer .o-form-section-formGroup-select-arrow {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 16px;
  transform: rotate(90deg);
}

.o-form-section-formGroup-inputContainer textarea {
  font-size: 15px;
  min-height: 100px;
}

.o-form-section-formGroup-inputContainer textarea::placeholder {
  color: #a0acb7;
}

.o-form-section-formGroup-inputContainer input[type="password"], .o-form-section-formGroup-inputContainer input[type="number"], .o-form-section-formGroup-inputContainer input[type="text"], .o-form-section-formGroup-inputContainer input[type="email"], .o-form-section-formGroup-inputContainer input[type="url"], .o-form-section-formGroup-inputContainer textarea {
  border: 1px solid #ddd;
  border-radius: 6px;
  outline: none;
  width: 100%;
  padding: 15px 20px;
}

.o-form-section-formGroup-inputContainer input[type="password"]:focus, .o-form-section-formGroup-inputContainer input[type="number"]:focus, .o-form-section-formGroup-inputContainer input[type="text"]:focus, .o-form-section-formGroup-inputContainer input[type="email"]:focus, .o-form-section-formGroup-inputContainer input[type="url"]:focus, .o-form-section-formGroup-inputContainer textarea:focus, .o-form-section-formGroup-inputContainer select:focus {
  border-color: #294de6;
}

.o-form-section-formGroup-inputContainer input[type="password"]:read-only, .o-form-section-formGroup-inputContainer input[type="number"]:read-only, .o-form-section-formGroup-inputContainer input[type="text"]:read-only, .o-form-section-formGroup-inputContainer input[type="email"]:read-only, .o-form-section-formGroup-inputContainer input[type="url"]:read-only, .o-form-section-formGroup-inputContainer textarea:read-only {
  background-color: #f3f3f3;
  color: #708293;
}

.o-form-section-formGroup-inputContainer input[type="password"]:read-only:focus, .o-form-section-formGroup-inputContainer input[type="number"]:read-only:focus, .o-form-section-formGroup-inputContainer input[type="text"]:read-only:focus, .o-form-section-formGroup-inputContainer input[type="email"]:read-only:focus, .o-form-section-formGroup-inputContainer input[type="url"]:read-only:focus, .o-form-section-formGroup-inputContainer textarea:read-only:focus {
  border: 1px solid #ddd;
}

.o-form-section-formGroup-inputContainer-selectCaret {
  position: absolute;
  right: 15px;
  top: 15px;
}

.o-form-section-formGroup-inputContainer-selectCaret i {
  color: #112f4b;
  position: relative;
  display: block;
}

.o-form-section-formGroup .a-errorMessageList {
  margin-top: 10px;
}

.o-form-section-heading {
  font-size: 18px;
  font-weight: bold;
}

.o-form-section-heading + .o-form-section-formGroup {
  margin-top: 10px;
}

.o-form-submitSection-formGroup-inputContainer {
  display: flex;
  justify-content: center;
}

.o-form-submitSection-formGroup-inputContainer input[type="submit"], .o-form-submitSection-formGroup-inputContainer button {
  font-size: 15px;
  min-width: 200px;
  padding: 20px;
  display: flex;
  justify-content: center;
}
