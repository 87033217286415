.t-twoPaneWithSideMainPane {
  display: flex;
  flex: 1;
}

.t-twoPaneWithSideMainPane-sidePane {
  width: 210px;
  display: flex;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.05);
}
.t-twoPaneWithSideMainPane-sidePane .o-globalSidebar{
    width: 100%;
  }

.t-twoPaneWithSideMainPane-mainPaneContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.t-twoPaneWithSideMainPane-mainPaneContainer-mainPane{
    display: flex;
    flex: 1;
    background-color: #fcfcfc;
    padding: 35px 40px;
  }
.t-twoPaneWithSideMainPane-mainPaneContainer-mainPane-main{
      display: flex;
      flex: 0 1 auto;
      max-width: 1000px;
      flex-direction: column;
    }
.t-twoPaneWithSideMainPane-mainPaneContainer-mainPane-side{
      width: 260px;
      display: flex;
      flex-direction: column;
      margin: 0 20px;
    }
