.t-twoPane {
  display: flex;
  flex: 1;
}

.t-twoPane-sidePane {
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-shrink: 0;
  width: 210px;
}
.t-twoPane-sidePane .o-globalSidebar{
    min-width: 100%;
  }

.t-twoPane-mainPane {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.t-twoPane-mainPane-main{
    background-color: #F7FAFC;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 20px 40px 100px;
  }
