.p-publicPosition-parentBg {
  background: #fcfcfc;
  padding: 50px 0;
}
.p-publicPosition-parentBg @media screen and (max-width: 720px){
    padding: 30px 0;
  }
.p-publicPosition-parentBg @media screen and (max-width: 720px) .t-singlePane-mainPane-main{
      padding: 0;
      width: 100%;
    }

.p-publicPosition {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: inset 0 -1px 0 #ddd;
  padding: 30px 30px 40px;
  width: 100%;
}
.p-publicPosition @media screen and (max-width: 720px){
    border-right: 0;
    border-left: 0;
    border-radius: 0;
  }
.p-publicPosition-header{
    border-bottom: 1px solid #ddd;
    display: flex;
    margin: 0 -30px 30px;
    padding: 0 30px 30px;
  }
.p-publicPosition-logo{
    display: block;
  }
.p-publicPosition-logo img{
      width: 150px;
    }
.p-publicPosition-logo @media screen and (max-width: 720px) img{
        width: 120px;
      }
.p-publicPosition-breadcrumb{
    color: #708293;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
.p-publicPosition-breadcrumb a{
      color: #708293;
    }
.p-publicPosition-breadcrumb a:hover{
        text-decoration: underline;
      }
.p-publicPosition-breadcrumb i{
      font-size: 16px;
      color: #708293;
    }
.p-publicPosition-body h1{
      font-size: 25px;
      margin: 5px 0 20px;
    }
.p-publicPosition-list{
    list-style: none;
    margin-top: 20px;
  }
.p-publicPosition-list-item + .p-publicPosition-list-item{
        margin-top: 20px;
      }
.p-publicPosition-list-item h3{
        color: #112f4b;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 3px;
      }
.p-publicPosition-list-item p{
        color: #708293;
      }
.p-publicPosition-list-item a:hover h3{
          color: #294de6;
          text-decoration: underline;
        }
.p-publicPosition-list-item @media screen and (max-width: 720px) p{
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
.p-publicPosition-jobDescription p{
      margin-bottom: 40px;
    }
.p-publicPosition-applyForm input[type="text"]:focus,.p-publicPosition-applyForm select:focus,.p-publicPosition-applyForm textarea:focus{
        border-color: #294de6 !important;
      }
.p-publicPosition-applyForm input[type="text"]::placeholder,.p-publicPosition-applyForm select::placeholder,.p-publicPosition-applyForm textarea::placeholder{
        color: #a0acb7;
      }
.p-publicPosition-applyButton{
    border-radius: 50px;
    background-color: #294de6;
    color: #fff;
    padding: 12px 20px;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
    display: block;
    width: 185px;
    text-align: center;
    margin: 0 auto;
  }
.p-publicPosition-applyButton:hover{
      opacity: 0.9;
    }
.p-publicPosition-footer{
    padding: 20px 0;
    color: #708293;
    text-align: center;
    font-size: 12px;
  }
.p-publicPosition-footer img{
      width: 180px;
    }
