.a-processCount {
  text-align: center;
}
.a-processCount-heading{
    color: #708293;
    font-size: 11px;
    font-weight: bold;
  }
.a-processCount-count{
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
  }
