.o-positionPanelListNavigation {
  align-items: center;
  display: flex;
}
.o-positionPanelListNavigation-title{
    font-size: 24px;
  }
.o-positionPanelListNavigation-newPositionButtonContainer{
    margin-left: auto;
  }
