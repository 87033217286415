.a-primaryButton {
  align-items: center;
  background-color: #294de6;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  font-weight: bold;
  padding: 10px 28px;
  text-decoration: none;
}
.a-primaryButton:hover{
    background-color: #213eb8;
  }
.a-primaryButton:disabled{
    cursor: not-allowed;
    background-color: #7f94f0;
  }
.a-primaryButton .material-icons{
    font-size: 18px;
    margin-left: -5px;
    margin-right: 10px;
  }
