.m-userLargePanel {
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  position: relative;
}
.m-userLargePanel-editButton{
    position: absolute;
    top: 10px;
    right: 10px;
  }
.m-userLargePanel-name{
    font-weight: bold;
    font-size: 18px;
    margin-top: 20px;
  }
.m-userLargePanel-section{
    color: #708293;
    font-size: 13px;
    margin-top: 5px;
  }

.m-userLargePanel-dateContainer {
  display: flex;
  color: #708293;
  font-size: 12px;
  margin-top: 20px;
}
.m-userLargePanel-dateContainer-date + .m-userLargePanel-dateContainer-date{
      margin-left: 10px;
    }

.m-userLargePanel-processCountList {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.m-userLargePanel-processCountList .a-processCount{
    margin: 0 20px 20px 0;
    width: calc(33.3333333% - 40px / 3);
  }
.m-userLargePanel-processCountList .a-processCount:nth-child(3n){
      margin-right: 0;
    }
