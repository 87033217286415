.o-activityForm-formGroup {
  border-bottom: solid 1px #ddd;
  width: calc(100% - 24px);
  margin: 0 12px;
  display: block;
  padding: 15px 0;
}
.o-activityForm-formGroup:first-child{
    padding: 8px 0 15px;
  }
.o-activityForm-formGroup:last-child{
    border-bottom: none;
  }
.o-activityForm-formGroup-title{
    color: #708293;
    font-size: 12px;
    line-height: 20px;
    display: inline-block;
    margin-right: 10px;
  }
.o-activityForm-formGroup input[type="text"]{
    outline: none;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
  }
.o-activityForm-formGroup input[type="text"]::placeholder{
      color: #a0acb7;
    }
.o-activityForm-formGroup-selectContainer{
    position: relative;
    display: inline-block;
  }
.o-activityForm-formGroup-selectContainer select{
      font-size: 13px;
      line-height: 20px;
      padding: 5px 24px 5px 10px;
      position: relative;
      border: solid 1px #ddd;
      border-radius: 6px;
      outline: none;
      width: 100%;
    }
.o-activityForm-formGroup-selectContainer select::placeholder{
        color: #a0acb7;
      }
.o-activityForm-formGroup .inputUnit{
    font-size: 12px;
    margin: 0 5px;
    color: #112f4b;
  }
.o-activityForm-formGroup-timeContainer{
    display: inline-block;
  }
.o-activityForm-formGroup-timeContainer-showButton{
      font-size: 13px;
      line-height: 20px;
      padding: 5px 10px;
      position: relative;
      border: solid 1px #ddd;
      border-radius: 6px;
      outline: none;
      width: 60px;
      text-align: center;
    }
.o-activityForm-formGroup-timeContainer-showButton.is-active{
        border: solid 1px #294de6;
      }
.o-activityForm-formGroup-timeContainer-showButton.is-invalid{
        border: solid 1px #ff3c3c;
      }
.o-activityForm-formGroup-timeContainer-timePicker{
      top: 15px;
    }
.o-activityForm-formGroup-guestsContainer{
    align-items: center;
    display: flex;
    width: 100%;
  }
.o-activityForm-formGroup-guestsContainer:hover{
    cursor: pointer;
}
.o-activityForm-formGroup-guestsContainer-label{
      color: #a0acb7;
      font-size: 12px;
      margin-right: 25px;
      white-space: nowrap;
      padding-top: 1px;
    }
.o-activityForm-formGroup-guestsContainer-guestsNameWrapper{
      width: 100%;
    }
.o-activityForm-formGroup-guestsContainer-guestsName::after{
        content: " ";
        display: inline-block;
        margin-right: 8px;
      }
.o-activityForm-formGroup-guestsContainer-guestsName:last-child::after{
        content: "";
        margin-right: 0;
      }
.o-activityForm-formGroup-guestsContainer-placeholder{
      color: #a0acb7;
      font-size: 14px;
      line-height: 20px;
    }
.o-activityForm-formGroup-guestsContainer-picker{
      top: 20px;
    }
.o-activityForm-formGroup-noPadding{
    width: 100%;
    padding: 15px 0;
  }
.o-activityForm-formGroup-noPadding:first-child{
      padding: 0 0 15px 0;
    }
.o-activityForm-formGroup-noPadding:last-child{
      border-bottom: none;
    }
.o-activityForm-formGroup-noPadding-textarea{
      width: 100%;
      padding: 0 15px 0 15px;
    }
.o-activityForm-formGroup-noPadding-textarea .fr-box{
        padding-bottom: 20px;
      }
