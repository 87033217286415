.o-accessTokensPanel {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.03);
  padding: 40px;
}
.o-accessTokensPanel-title{
    color: #708293;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
