.a-whitePrimaryButton {
  align-items: center;
  background-color: #fff;
  border: 1px solid #294de6;
  border-radius: 4px;
  color: #294de6;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  padding: 10px 20px;
  text-decoration: none;
}
.a-whitePrimaryButton:hover{
    background-color: #f2f2f2;
  }
.a-whitePrimaryButton:disabled{
    cursor: not-allowed;
    background-color: #e6e6e6;
  }
.a-whitePrimaryButton .material-icons{
    font-size: 18px;
    margin-left: -5px;
    margin-right: 10px;
  }
