.landingPage {
  -webkit-font-smoothing: antialiased;
  font-feature-settings: "palt";
  min-width: 1080px;
  font-size: 18px;
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}
.landingPage @media screen and (max-width: 1023px){
    min-width: 0;
    overflow: hidden;
  }
.landingPage-button{
    background-color: #294de6;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 25px;
    font-size: 13px;
    border-radius: 20px;
    color: #fff;
    font-weight: bold;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    transition: 0.2s;
  }
.landingPage-button:hover{
      background-color: #213eb8;
    }
.landingPage-button.is-large{
      height: 60px;
      font-size: 16px;
      border-radius: 30px;
      padding: 0 40px;
    }
.landingPage-annotation{
    font-size: 14px;
    color: #708293;
  }
.landingPage-gray{
    background: #fcfcfc;
  }

.landingPageHeader {
  display: flex;
  align-items: center;
  padding: 0 30px;
}
.landingPageHeader @media screen and (max-width: 1023px){
    padding: 0 15px;
  }
.landingPageHeader-logo{
    display: block;
    padding: 30px 0;
  }
.landingPageHeader-logo @media screen and (max-width: 1023px){
      padding: 15px 0;
    }
.landingPageHeader-logo img{
      width: auto;
      height: 50px;
    }
.landingPageHeader-logo img @media screen and (max-width: 1023px){
        height: 35px;
      }
.landingPageHeader-right{
    margin-left: auto;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
.landingPageHeader-right @media screen and (max-width: 1023px) .landingPage-button{
        height: 30px;
        font-size: 11px;
        padding: 0 15px;
      }
.landingPageHeader-loginButton{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 25px;
    font-size: 13px;
    font-weight: bold;
    border: 2px solid #294de6;
    border-radius: 35px;
    transition: 0.2s;
  }
.landingPageHeader-loginButton @media screen and (max-width: 767px){
      display: none;
    }
.landingPageHeader-loginButton:hover{
      background: #294de6;
      color: #fff;
    }

.landingPage-main {
  padding-top: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.landingPage-main @media screen and (max-width: 1023px){
    padding-top: 20px;
  }
.landingPage-main header{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
.landingPage-main header h1{
      font-size: 66px;
    }
.landingPage-main header h1 @media screen and (max-width: 1023px){
        font-size: 42px;
        line-height: 1.3;
        margin-bottom: 10px;
      }
.landingPage-main header h1 br{
        display: none;
      }
.landingPage-main header h1 br @media screen and (max-width: 1023px){
          display: inline-block;
        }
.landingPage-main header h2{
      font-size: 32px;
      color: #41596f;
      font-weight: normal;
    }
.landingPage-main header h2 @media screen and (max-width: 1023px){
        font-size: 24px;
      }
.landingPage-main header h2 br{
        display: none;
      }
.landingPage-main header h2 br @media screen and (max-width: 1023px){
          display: inline-block;
        }
.landingPage-main header .landingPage-button{
      margin-top: 30px;
    }
.landingPage-main header .landingPage-button @media screen and (max-width: 1023px){
        margin-top: 20px;
      }
.landingPage-main-bg{
    display: block;
    margin-top: 40px;
  }
.landingPage-main-bg @media screen and (max-width: 1023px){
      width: 100%;
      height: auto;
      margin-top: 20px;
    }
.landingPage-main-message{
    width: 100%;
    background: #294de6;
    position: relative;
    padding: 60px 0 50px;
    color: #fff;
  }
.landingPage-main-message @media screen and (max-width: 1023px){
      padding: 30px 0;
    }
.landingPage-main-message h3{
      font-size: 35px;
      margin-bottom: 12px;
    }
.landingPage-main-message h3 @media screen and (max-width: 1023px){
        font-size: 26px;
      }
.landingPage-main-message h3 br{
        display: none;
      }
.landingPage-main-message h3 br @media screen and (max-width: 1023px){
          display: inline-block;
        }
.landingPage-main-message p{
      line-height: 1.6;
    }
.landingPage-main-message @media screen and (max-width: 1023px) p{
        padding: 0 15px;
      }
.landingPage-main-message @media screen and (max-width: 1023px) p br{
          display: none;
        }
.landingPage-main-message img{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -217px;
    }
.landingPage-main-message img @media screen and (max-width: 1023px){
        width: 100%;
        height: auto;
        position: relative;
        top: 0;
        margin-top: -20%;
      }

.landingPage-body {
  width: 1080px;
  margin: 0 auto;
}
.landingPage-body @media screen and (max-width: 1023px){
    width: auto;
  }

.landingPage-section {
  padding: 70px 0 0;
}
.landingPage-section @media screen and (max-width: 1023px){
    padding-top: 40px;
  }
.landingPage-section-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
.landingPage-section-title{
    font-size: 22px;
    letter-spacing: 3px;
    border-bottom: 2px solid #294de6;
    padding-bottom: 4px;
  }
.landingPage-section-subTitle{
    margin-top: 8px;
    font-size: 15px;
    letter-spacing: 2px;
  }

.landingPage-feature {
  display: flex;
  align-items: center;
  padding: 30px 0;
}
.landingPage-feature @media screen and (max-width: 1023px){
    flex-direction: column;
  }
.landingPage-feature @media screen and (max-width: 1023px) + .landingPage-feature{
      margin-top: 20px;
    }
.landingPage-feature.is-alt-layout{
    flex-direction: row-reverse;
  }
.landingPage-feature.is-alt-layout @media screen and (max-width: 1023px){
      flex-direction: column;
    }
.landingPage-feature.is-alt-layout .landingPage-feature-body{
      margin-left: 0;
      margin-right: 60px;
    }
.landingPage-feature.is-alt-layout .landingPage-feature-body @media screen and (max-width: 1023px){
        margin-right: 0;
      }
.landingPage-feature-image{
    flex: 0 0 510px;
    width: 510px;
    height: 510px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
.landingPage-feature-image @media screen and (max-width: 1023px){
      flex: 0;
      display: block;
      width: auto;
      height: auto;
    }
.landingPage-feature-image img{
      display: block;
    }
.landingPage-feature-image img @media screen and (max-width: 1023px){
        width: calc(60% - 50px);
        height: auto;
        margin: 0 auto;
      }
.landingPage-feature-image img @media screen and (max-width: 750px){
        width: calc(100% - 50px);
        height: auto;
        margin: 0 auto;
      }
.landingPage-feature-body{
    margin-left: 60px;
  }
.landingPage-feature-body @media screen and (max-width: 1023px){
      margin: 30px 0 0;
      padding: 0 25px;
    }
.landingPage-feature-body h2{
      color: #a0acb7;
      font-size: 22px;
      letter-spacing: 3px;
      margin-bottom: 10px;
    }
.landingPage-feature-body h1{
      font-size: 52px;
      line-height: 1.2;
      margin-bottom: 20px;
    }
.landingPage-feature-body h1 @media screen and (max-width: 1023px){
        font-size: 4px;
      }
.landingPage-feature-body h1 @media screen and (max-width: 1023px) br{
          display: none;
        }
.landingPage-feature-body h1 @media screen and (max-width: 470px){
        font-size: 4px;
      }
.landingPage-feature-body h1 @media screen and (max-width: 470px) br{
          display: inline-block;
        }
.landingPage-feature-body .landingPage-annotation{
      margin-top: 10px;
    }
.landingPage-feature-body a{
      font-size: 18px;
      font-weight: bold;
      display: flex;
      align-items: center;
      margin-top: 20px;
      transition: 0.2s;
    }
.landingPage-feature-body a:hover{
        color: #213eb8;
      }
.landingPage-feature-body a i{
        font-size: 30px;
      }

.landingPage-customers {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.landingPage-customers-item{
    flex: 0 0 155px;
    margin: 0 10px;
  }
.landingPage-customers-item @media screen and (max-width: 1023px){
      flex: 0 0 calc((100% - 50px) / 4);
      width: calc((100% - 50px) / 4);
      margin: 0 5px;
    }
.landingPage-customers-item @media screen and (max-width: 1023px) img{
        width: 100%;
        height: auto;
      }

.landingPage-case {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.landingPage-case @media screen and (max-width: 750px){
    display: flex;
    flex-direction: column;
    align-items: center;
  }
.landingPage-case.col-2 li{
      width: calc(100% / 2 - 40px);
      max-width: 340px;
      margin: 0 15px 40px;
    }
.landingPage-case.col-2 li @media screen and (max-width: 750px){
        width: calc(100% - 30px);
        margin-bottom: 40px;
      }
.landingPage-case li{
    width: calc(100% / 3 - 30px);
    margin: 0 15px 30px;
    list-style-type: none;
    position: relative;
  }
.landingPage-case li @media screen and (max-width: 750px){
      width: calc(100% - 30px);
      margin-bottom: 40px;
    }
.landingPage-case li .landingPage-case-image-wrapper{
      border-radius: 4px;
      overflow: hidden;
      padding-top: 55%;
      height: 0;
      position: relative;
      width: 100%;
      margin-bottom: 15px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
.landingPage-case li .landingPage-case-image-wrapper.spready{
        background-image: url("../images/case_page/spready/top.jpg");
      }
.landingPage-case li .landingPage-case-image-wrapper.regrit{
        background-image: url("../images/case_page/regrit/top.jpg");
      }
.landingPage-case li .landingPage-case-image-wrapper.codmon{
        background-image: url("../images/case_page/codmon/top.jpg");
      }
.landingPage-case li .landingPage-case-image-wrapper.open8{
        background-image: url("../images/case_page/open8/image2.jpg");
      }
.landingPage-case li .landingPage-case-image-wrapper.open8-2{
        background-image: url("../images/case_page/open8/image3.jpg");
      }
.landingPage-case li .landingPage-case-image-wrapper .landingPage-case-company{
        background-color: #02b2e9;
        padding: 8px 10px;
        position: absolute;
        top: 0;
        left: 0;
        color: #fff;
        font-weight: bold;
        font-size: 14px;
      }
.landingPage-case li .landingPage-case-image-wrapper img{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        max-width: inherit;
        z-index: 0;
      }
.landingPage-case li h3{
      font-size: 16px;
      color: #112f4b;
    }
.landingPage-case li .landingPage-case-description-wrapper{
      overflow: hidden;
      width: 100%;
      background-color: #fff;
      margin-top: 5px;
    }
.landingPage-case li .landingPage-case-description-wrapper p{
        font-size: 12px;
        line-height: 1.8;
        height: calc(12px * 1.8 * 2);
        position: relative;
      }
.landingPage-case li .landingPage-case-description-wrapper p::before,.landingPage-case li .landingPage-case-description-wrapper p::after{
          background-color: #fff;
          position: absolute;
        }
.landingPage-case li .landingPage-case-description-wrapper p::before{
          content: "...";
          top: calc(12px * 1.8 * 1);
          right: 0;
        }
.landingPage-case li .landingPage-case-description-wrapper p::after{
          content: "";
          height: 100%;
          width: 100%;
        }
.landingPage-case li a{
      font-size: 14px;
      font-weight: bold;
      margin-top: 10px;
      display: block;
    }
.landingPage-case li a .material-icons{
        vertical-align: middle;
      }

.landingPage-callToAction {
  background: #3147a3;
  color: #fff;
  height: 200px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-right: 60px;
  margin: 70px 0;
}
.landingPage-callToAction @media screen and (max-width: 1023px){
    flex-direction: column;
    border-radius: 0;
    height: auto;
    padding: 30px 20px;
    margin: 30px 0 0;
  }
.landingPage-callToAction header{
    padding: 0 50px;
    flex: 1;
  }
.landingPage-callToAction header @media screen and (max-width: 1023px){
      padding: 0;
      margin-bottom: 20px;
    }
.landingPage-callToAction h1{
    font-size: 30px;
  }
.landingPage-callToAction h1 @media screen and (max-width: 1023px){
      font-size: 24px;
      text-align: center;
    }
.landingPage-callToAction h2{
    font-size: 18px;
    font-weight: normal;
    margin-top: 10px;
  }
.landingPage-callToAction h2 @media screen and (max-width: 1023px){
      text-align: center;
    }

.landingPage-team {
  padding-bottom: 80px;
}
.landingPage-team @media screen and (max-width: 1023px){
    padding-bottom: 40px;
  }
.landingPage-team-memberRow{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -15px 30px;
  }
.landingPage-team-memberRow @media screen and (max-width: 1023px){
      margin: 0 15px;
      justify-content: space-evenly;
    }
.landingPage-team-member{
    flex: 0 0 192px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 15px;
  }
.landingPage-team-member @media screen and (max-width: 1023px){
      flex: 0 0 125px;
      margin: 0 0 20px;
      align-items: flex-start;
    }
.landingPage-team-member-title{
      color: #708293;
      font-size: 14px;
      margin-bottom: 10px;
    }
.landingPage-team-member-avatarAndName{
      display: flex;
      justify-content: center;
      align-items: center;
    }
.landingPage-team-member-avatar img{
        display: block;
      }
.landingPage-team-member-name{
      font-weight: bold;
      font-size: 14px;
      margin-left: 10px;
    }
.landingPage-team-joinButton{
    width: 300px;
    height: 70px;
    display: flex;
    margin: 50px auto 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 2px solid #294de6;
    border-radius: 35px;
    transition: 0.2s;
  }
.landingPage-team-joinButton @media screen and (max-width: 1023px){
      margin-top: 20px;
    }
.landingPage-team-joinButton:hover{
      background: #294de6;
      color: #fff;
    }
.landingPage-team-joinButton .top{
      font-weight: bold;
      margin-bottom: 2px;
    }
.landingPage-team-joinButton .bottom{
      font-size: 13px;
    }
.landingPage-team-joinButton i{
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }

.landingPageFooter {
  padding: 45px 0;
}
.landingPageFooter @media screen and (max-width: 1023px){
    padding: 30px 0;
  }
.landingPageFooter-inner{
    width: 1080px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
.landingPageFooter-inner @media screen and (max-width: 1023px){
      flex-direction: column;
      width: auto;
    }
.landingPageFooter-right{
    margin-left: auto;
    display: flex;
    align-items: center;
  }
.landingPageFooter-right @media screen and (max-width: 1023px){
      flex-direction: column;
      margin-top: 20px;
      align-items: center;
      margin-left: 0;
    }
.landingPageFooter-logo img{
      height: 40px;
      width: auto;
    }
.landingPageFooter-copyright{
    font-size: 12px;
    color: #a0acb7;
  }
.landingPageFooter-menu{
    font-size: 15px;
    display: flex;
    margin-right: 25px;
  }
.landingPageFooter-menu @media screen and (max-width: 1023px){
      justify-content: center;
      flex-wrap: wrap;
      font-size: 12px;
      margin: 0 0 20px;
    }
.landingPageFooter-menu a{
      color: inherit;
      display: block;
      margin: 0 15px;
      transition: 0.2s;
    }
.landingPageFooter-menu a @media screen and (max-width: 1023px){
        margin: 0 5px;
      }
.landingPageFooter-menu a:hover{
        color: #294de6;
      }

.landingPage-childSection {
  margin-top: 60px;
}
.landingPage-childSection @media screen and (max-width: 1023px){
    padding: 0 15px;
  }
.landingPage-childSection > h1{
    font-size: 22px;
    margin-bottom: 30px;
    color: #41596f;
  }
.landingPage-releases-item{
    display: flex;
    color: inherit;
    font-size: 16px;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 12px 0;
  }
.landingPage-releases-item:hover .title{
        color: #294de6;
      }
.landingPage-releases-item .date{
      color: #41596f;
      min-width: 100px;
      margin-right: 20px;
    }
.landingPage-releases-item .date @media screen and (max-width: 1023px){
        font-size: 12px;
        min-width: 70px;
      }
.landingPage-releases-item .title{
      font-weight: bold;
      font-size: 16px;
    }
.landingPage-releases-item .title @media screen and (max-width: 1023px){
        font-size: 14px;
      }

.landingPage-assets {
  display: flex;
}
.landingPage-assets @media screen and (max-width: 1023px){
    flex-wrap: wrap;
  }
.landingPage-assets-item{
    border: 1px solid #eee;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.05);
    margin-right: 20px;
  }
.landingPage-assets-item @media screen and (max-width: 1023px){
      max-width: 100%;
    }
.landingPage-assets-item @media screen and (max-width: 1023px) img{
        max-width: 100%;
        height: auto;
      }
.landingPage-assets-item @media screen and (max-width: 1023px) + .landingPage-assets-item{
        margin-top: 20px;
      }
.landingPage-screenshots-mobile{
    display: flex;
    margin: 0 -10px;
  }
.landingPage-screenshots-mobile @media screen and (max-width: 1023px){
      flex-wrap: wrap;
    }
.landingPage-screenshots-mobile-item{
      margin: 0 10px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }
.landingPage-screenshots-mobile-item @media screen and (max-width: 1023px){
        width: calc((100% - 40px) / 2);
        margin-bottom: 20px;
      }
.landingPage-screenshots-mobile-item img{
        width: 255px;
      }
.landingPage-screenshots-mobile-item img @media screen and (max-width: 1023px){
          width: 100%;
          height: auto;
        }
.landingPage-screenshots-pc{
    display: flex;
    margin: 40px -10px 0;
  }
.landingPage-screenshots-pc @media screen and (max-width: 1023px){
      margin-top: 20px;
    }
.landingPage-screenshots-pc-item{
      margin: 0 10px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }
.landingPage-screenshots-pc-item img{
        width: 530px;
      }
.landingPage-screenshots-pc-item img @media screen and (max-width: 1023px){
          width: 100%;
          height: auto;
        }

.landingPage-banners {
  display: flex;
}
.landingPage-banners-item img{
      width: 640px;
    }
.landingPage-banners-item img @media screen and (max-width: 1023px){
        width: 100%;
        height: auto;
      }@keyframes mmfadeIn from{
    opacity: 0;
  }@keyframes mmfadeIn to{
    opacity: 1;
  }@keyframes mmfadeOut from{
    opacity: 1;
  }@keyframes mmfadeOut to{
    opacity: 0;
  }@keyframes mmslideIn from{
    transform: translate(-50%, -35%);
  }@keyframes mmslideIn to{
    transform: translate(-50%, -50%);
  }@keyframes mmslideOut from{
    transform: translate(-50%, -50%);
  }@keyframes mmslideOut to{
    transform: translate(-50%, -35%);
  }

.landingPage-modal[aria-hidden="false"] .landingPage-modal-overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.landingPage-modal[aria-hidden="false"] .landingPage-modal-container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.landingPage-modal[aria-hidden="true"] .landingPage-modal-overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.landingPage-modal[aria-hidden="true"] .landingPage-modal-container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.landingPage-modal-container,
.landingPage-modal-overlay {
  will-change: transform;
}

.landingPage-modal {
  display: none;
}
.landingPage-modal.is-open{
    display: block;
  }
.landingPage-modal-closeButton{
    position: absolute;
    transform: translate(50%, -50%);
    top: 0;
    right: 0;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
.landingPage-modal-closeButton i{
      font-size: 18px;
      pointer-events: none;
    }
.landingPage-modal-overlay{
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
.landingPage-modal-container{
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    border: 1px solid #ddd;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
  }
.landingPage-modal-container-inner{
      max-height: 90vh;
      overflow-y: scroll;
      padding: 40px 30px;
    }
.landingPage-modal-container-inner @media screen and (max-width: 1023px){
        width: calc(100vw - 40px);
        padding: 20px 15px;
      }

.landingPage-form {
  font-size: 16px;
}
.landingPage-form-input{
    padding: 0 10px;
    height: 40px;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: 0;
  }
.landingPage-form-input:focus{
      border-color: #294de6;
    }
.landingPage-form-checkList-item{
      margin-top: 5px;
    }
.landingPage-form-checkList-item input{
        margin-right: 5px;
      }
.landingPage-form select{
    -moz-appearance: menulist;
    -webkit-appearance: menulist;
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 40px;
    outline: 0;
  }
.landingPage-form select:focus{
      border-color: #294de6;
    }
.landingPage-form textarea{
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    height: 100px;
    outline: 0;
  }
.landingPage-form textarea:focus{
      border-color: #294de6;
    }
.landingPage-form-column{
    display: flex;
    flex-direction: column;
  }
.landingPage-form-column + .landingPage-form-column{
      margin-top: 20px;
    }
.landingPage-form-column > label{
      font-weight: bold;
      margin-bottom: 5px;
    }
.landingPage-form-column > label .required{
        color: red;
      }
.landingPage-form-actions{
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
.landingPage-form + .landingPage-form-thankyou{
    display: none;
  }
.landingPage-form-thankyou{
    font-size: 16px;
    text-align: center;
  }
.landingPage-form-thankyou-header{
      font-size: 18px;
      font-weight: bold;
      color: #294de6;
      margin-bottom: 5px;
    }
.landingPage-form-thankyou button{
      margin: 20px auto 0;
    }
