.o-userPanelListContainer .m-pagination{
    display: flex;
    justify-content: center;
    margin: 20px 0;
    font-size: 16px;
  }

.o-userPanelList {
  display: table;
  border-radius: 6px;
  border: 1px solid #ddd;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.03);
  background-color: #fff;
}

.o-userPanelList-row {
  color: #112f4b;
  display: table-row;
  box-shadow: inset 0 -1px 0 #ddd;
  font-size: 13px;
  text-decoration: none;
}
.o-userPanelList-row:hover{
    background-color: #f2f2f2;
  }

.o-userPanelList-headerRow {
  color: #708293;
  display: table-row;
  box-shadow: inset 0 -1px 0 #ddd;
  font-size: 12px;
  font-weight: bold;
  height: 50px;
  white-space: nowrap;
}

.o-userPanelList-cell {
  display: table-cell;
  padding: 14px 15px;
  text-align: left;
  vertical-align: middle;
  width: 1%;
}
.o-userPanelList-cell:first-child{
    padding-left: 30px;
  }
.o-userPanelList-cell:last-child{
    padding-right: 30px;
    text-align: right;
  }

.o-userPanelList-imageContainer {
  width: 40px;
  height: 40px;
}
.o-userPanelList-imageContainer-image{
    width: 40px;
    height: 40px;
  }

.o-userPanelList-name {
  font-size: 15px;
  font-weight: bold;
  white-space: nowrap;
}

.o-userPanelList-nameContainer {
  align-items: center;
  display: flex;
}
.o-userPanelList-nameContainer .o-userPanelList-name{
    margin-left: 15px;
  }

.o-userPanelList-roleContainer {
  color: #708293;
  font-size: 12px;
  min-width: 150px;
  display: flex;
  align-items: center;
}
.o-userPanelList-roleContainer.is-noSection{
    color: #a0acb7;
  }

.o-userPanelList-statuses {
  display: flex;
}
.o-userPanelList-statuses .a-processCount{
    white-space: nowrap;
  }
.o-userPanelList-statuses .a-processCount + .a-processCount{
      margin-left: 20px;
    }

.o-userPanelList-assigns {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
}

.o-userPanelList-lastActivityDate {
  color: #708293;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
}

.o-userPanelList-createdDate {
  color: #708293;
  font-size: 12px;
  white-space: nowrap;
  text-align: right;
}
