.m-pagination {
  align-items: center;
  display: flex;
  justify-content: center;
}
.m-pagination .page,.m-pagination .prev,.m-pagination .first,.m-pagination .next,.m-pagination .last{
    margin: 0 2px;
  }
.m-pagination a.previous,.m-pagination a.next{
      align-items: center;
      color: #112f4b;
      display: flex;
      font-size: 14px;
      font-weight: bold;
      height: 36px;
      justify-content: center;
      margin: 0 15px;
      min-width: 36px;
      text-align: center;
    }
.m-pagination a.previous:hover,.m-pagination a.next:hover{
        color: #294de6;
      }
.m-pagination a.disabled,.m-pagination a.disabled:hover{
        color: #708293;
        cursor: default;
        pointer-events: none;
      }
.m-pagination .current,.m-pagination .page a,.m-pagination .prev a,.m-pagination .first a,.m-pagination .next a,.m-pagination .last a{
    align-items: center;
    border-radius: 18px;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    height: 36px;
    justify-content: center;
    min-width: 36px;
    padding: 0 6px;
    text-align: center;
  }
.m-pagination .current i,.m-pagination .page a i,.m-pagination .prev a i,.m-pagination .first a i,.m-pagination .next a i,.m-pagination .last a i{
      display: block;
    }
.m-pagination .current{
    background-color: #294de6;
    color: #fff;
  }
.m-pagination .page a{
      color: #112f4b;
    }
.m-pagination .prev a,.m-pagination .first a,.m-pagination .next a,.m-pagination .last a{
      background-color: #ddd;
      color: #112f4b;
    }
.m-pagination .prev a:hover,.m-pagination .first a:hover,.m-pagination .next a:hover,.m-pagination .last a:hover{
        background-color: #c7c7c7;
      }
.m-pagination button.previous,.m-pagination button.next{
      align-items: center;
      color: #112f4b;
      display: flex;
      font-size: 14px;
      font-weight: bold;
      height: 36px;
      justify-content: center;
      margin: 0 10px;
      min-width: 36px;
      text-align: center;
    }
.m-pagination button.previous:hover,.m-pagination button.next:hover{
        color: #294de6;
      }
.m-pagination button:disabled,.m-pagination button:disabled:hover{
        color: #708293;
        cursor: default;
      }
