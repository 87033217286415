.o-prospectProcessItemArchivedListNavigation {
  align-items: center;
  display: flex;
}

.o-prospectProcessItemArchivedListNavigation-title {
  font-size: 24px;
}

.o-prospectProcessItemArchivedListNavigation-buttonContainer {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.o-prospectProcessItemArchivedListNavigation-backLink {
  color: #708293;
  font-size: 14px;
  text-decoration: underline;
  margin-right: 20px;
}
.o-prospectProcessItemArchivedListNavigation-backLink:hover{
    opacity: 0.8;
  }
