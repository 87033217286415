.o-positionPanelListContainer {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.03);
}

.o-positionPanelList {
  display: table;
}

.o-positionPanelList-row {
  color: #112f4b;
  cursor: pointer;
  display: table-row;
  font-size: 15px;
  text-decoration: none;
}
.o-positionPanelList-row + .o-positionPanelList-row{
    box-shadow: inset 0 1px 0 #ddd;
  }
.o-positionPanelList-row:hover{
    background-color: #f2f2f2;
  }

.o-positionPanelList-headerRow {
  box-shadow: inset 0 -1px 0 #ddd;
  color: #708293;
  display: table-row;
  font-size: 12px;
  font-weight: bold;
  height: 50px;
  white-space: nowrap;
}

.o-positionPanelList-cell {
  display: table-cell;
  padding: 14px 15px;
  text-align: left;
  vertical-align: middle;
  width: 1%;
}
.o-positionPanelList-cell:first-child{
    padding-left: 30px;
  }
.o-positionPanelList-cell:last-child{
    padding-right: 30px;
    text-align: right;
  }

.o-positionPanelList-titleContainer {
  min-width: 250px;
}

.o-positionPanelList-title {
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
}
.o-positionPanelList-title:not(:last-child){
    margin-right: 5px;
  }

.o-positionPanelList-description {
  color: #41596f;
  font-size: 13px;
  min-width: 200px;
}
.o-positionPanelList-description > div[data-hypernova-key]{
    display: inline;
  }

.o-positionPanelList-statusesContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.o-positionPanelList-processCount,
.o-positionPanelList-prospectsCount {
  text-align: center;
  white-space: nowrap;
}
.o-positionPanelList-processCount-heading,.o-positionPanelList-prospectsCount-heading{
    color: #708293;
    font-size: 11px;
    font-weight: bold;
  }
.o-positionPanelList-processCount-count,.o-positionPanelList-prospectsCount-count{
    font-weight: bold;
    margin-top: 4px;
  }

.o-positionPanelList-processCount {
  position: relative;
}
.o-positionPanelList-processCount + .o-positionPanelList-processCount{
    padding-left: 20px;
  }

.o-positionPanelList-prospectsCount {
  margin-left: 30px;
}

.o-positionPanelList-totalCountButton {
  background-color: transparent;
  color: #294de6;
  font-size: 16px;
}
.o-positionPanelList-totalCountButton:hover{
    text-decoration: underline;
  }

.o-positionPanelList-lastActivityDate {
  color: #708293;
  font-size: 12px;
  text-align: center;
}

.o-positionPanelList-createdDate {
  color: #708293;
  font-size: 12px;
  white-space: nowrap;
}

.o-positionPanelList-readMoreButton {
  background-color: transparent;
  color: #294de6;
  font-size: 13px;
}
.o-positionPanelList-readMoreButton:hover{
    text-decoration: underline;
  }

.o-positionPanelList-countButton {
  background-color: transparent;
  color: #294de6;
  font-size: 13px;
}
.o-positionPanelList-countButton:hover{
    text-decoration: underline;
  }

.o-positionPanelList-emptyContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 350px;
  justify-content: center;
}
.o-positionPanelList-emptyContainer-emptyMessage{
    color: #708293;
    font-size: 15px;
    line-height: 1;
  }
.o-positionPanelList-emptyContainer-newPositionButtonContainer{
    display: inline-flex;
    margin-top: 20px;
  }
