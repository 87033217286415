.m-sns-share {
  display: inline-block;
  vertical-align: middle;
}
.m-sns-share a{
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
  }
.m-sns-share a img{
      width: 31px;
      height: 31px;
    }
