.t-threePane {
  display: flex;
  flex: 1;
}

.t-threePane-firstPane {
  width: 280px;
  display: flex;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0 / 0.05);
}
.t-threePane-firstPane .o-globalSidebar{
    width: 100%;
  }

.t-threePane-mainPaneContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.t-threePane-mainPaneContainer-mainPane{
    display: flex;
    flex: 1;
  }
.t-threePane-mainPaneContainer-mainPane-secondPane{
      display: flex;
      flex: 1;
      flex-direction: column;
      background-color: #fcfcfc;
      padding: 0 40px;
    }
.t-threePane-mainPaneContainer-mainPane-thirdPane{
      width: 290px;
      background-color: #f5f5f5f5;
      display: flex;
      flex-direction: column;
      padding: 0 20px;
    }
