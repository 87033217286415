.o-globalSidebar-serviceName {
  padding: 20px;
}
.o-globalSidebar-serviceName-imageContainer {
  display: block;
}
.o-globalSidebar-serviceName-imageContainer-image {
  display: block;
  width: 170px;
}
.o-globalSidebar-organizationName {
  align-items: center;
  background-color: #f3f3f3;
  color: #112f4b;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  padding: 15px 20px;
  margin-top: 20px;
}
.o-globalSidebar-navigationList {
  margin-top: 50px;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.o-globalSidebar-navigationList .o-globalSidebar-navigationList-item {
  align-items: center;
  color: #112f4b;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  padding: 12px 20px;
  text-decoration: none;
}
.o-globalSidebar-navigationList .o-globalSidebar-navigationList-item .o-globalSidebar-navigationList-item-icon {
  color: #294de6;
  font-size: 24px;
  margin-right: 10px;
}
.o-globalSidebar-navigationList .o-globalSidebar-navigationList-item:hover {
  background-color: #f2f2f2;
}
.o-globalSidebar-navigationList .o-globalSidebar-navigationList-item.is-active {
  background-color: #294de6;
  color: #fff;
}
.o-globalSidebar-navigationList .o-globalSidebar-navigationList-item.is-active .o-globalSidebar-navigationList-item-icon {
  color: #fff;
}
