.p-flyerApplicationToUserMailer .o-flyerApplicationToUserMailer-title {
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  font-size: 18px;
}
.p-flyerApplicationToUserMailer .o-flyerApplicationToUserMailer-title b {
  font-weight: bold;
}
.p-flyerApplicationToUserMailer .o-flyerApplicationToUserMailer-content {
  padding: 30px 0 40px;
}
.p-flyerApplicationToUserMailer .o-flyerApplicationToUserMailer-content-userName {
  color: #112f4b;
  font-size: 18px;
  line-height: 28px;
  margin: 0;
}
.p-flyerApplicationToUserMailer .o-flyerApplicationToUserMailer-content-positionTitle {
  margin-top: 15px;
  padding: 0;
  color: #708293;
  font-size: 12px;
  line-height: 24px;
}
