.o-publicFlyerApplicationContainer {
  padding: 15px 100px 40px;
}
.o-publicFlyerApplicationContainer @media screen and (max-width: 700px){
    padding: 15px 20px 40px;
  }
.o-publicFlyerApplicationContainer > h1{
    text-align: center;
    margin-bottom: 20px;
  }
.o-publicFlyerApplicationContainer > h1 @media screen and (max-width: 700px){
      font-size: 17px;
    }

.o-publicFlyerApplicationContainer-description {
  color: #112f4b;
  margin-bottom: 20px;
  font-size: 15px;
}

.o-publicFlyerApplicationContainer-requiredContainer {
  color: #ff3c3c;
  font-size: 14px;
  margin-bottom: 20px;
}

.o-publicFlyerApplicationContainer-required {
  color: #ff3c3c;
  display: inline-block;
  margin-left: 5px;
}

.o-publicFlyerApplicationContainer-submitContainer {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.o-publicFlyerApplicationContainer .o-publicFlyerApplicationContainer-submitButton {
  border-radius: 40px;
  padding: 20px 0;
  width: 280px;
  display: flex;
  justify-content: center;
}

.o-publicFlyerApplicationContainer-workHistoriesLabel {
  font-weight: bold;
  color: #708293;
  font-size: 14px;
  margin-top: 30px;
}

.o-publicFlyerApplicationContainer-wantedlyButtonContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
