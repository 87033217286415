.t-mailer {
  border: 0;
  margin: 0;
  padding: 0;
  height: 100% !important;
  width: 100% !important;
  color: #112f4b;
  font-family: -apple-system, "BlinkMacSystemFont", "Helvetica Neue", Helvetica, Arial, "Hiragino Sans",
  "Hiragino Kaku Gothic ProN", "Meiryo", "Segoe UI Symbol", sans-serif;
  font-size: 13px;
  line-height: 1.5;
  letter-spacing: 0.02em;
  background-color: #fcfcfc;
}

.t-mailer-container {
  max-width: 375px;
  border-spacing: 0;
  background-color: #fff;
  border: 1px solid #ddd;
}

.t-mailer-container-header {
  height: 40px;
  padding: 30px 20px 30px;
}

.t-mailer-container-main {
  padding: 0 20px 50px;
}

.t-mailer-container-footer {
  font-size: 13px;
  color: #708293;
  background-color: #f6f8fa;
  padding: 30px 20px 30px;
}

.t-mailer-container-footer .m-mailer-container-footer-links {
  margin-bottom: 20px;
  text-align: center;
}

.t-mailer-container-footer .m-mailer-container-footer-links-item {
  padding: 0 10px;
}

.t-flyerApplicationMailer {
  border: 0;
  margin: 0;
  padding: 0;
  height: 100% !important;
  width: 100% !important;
  color: #112f4b;
  font-family: -apple-system, "BlinkMacSystemFont", "Helvetica Neue", Helvetica, Arial, "Hiragino Sans",
  "Hiragino Kaku Gothic ProN", "Meiryo", "Segoe UI Symbol", sans-serif;
  font-size: 13px;
  line-height: 1.5;
  letter-spacing: 0.02em;
  background-color: #fcfcfc;
}

.t-flyerApplicationMailer-container {
  max-width: 375px;
  border-spacing: 0;
  background-color: #fff;
  border: 1px solid #ddd;
}

.t-flyerApplicationMailer-container-main {
  background-color: #f6f8fa;
}

.t-flyerApplicationMailer-container-footer {
  font-size: 13px;
  color: #708293;
  background-color: #f6f8fa;
  padding: 20px;
}

.t-flyerApplicationMailer-container-footer-producedBy {
  font-size: 11px;
  color: #8897a5;
}

.t-flyerApplicationMailer-container-footer-logo {
  padding-top: 30px;
}

.t-flyerApplicationMailer-container-footer-links {
  padding-top: 10px;
}

.t-flyerApplicationMailer-container-footer-links-item {
  padding: 0 10px;
  font-size: 11px;
}

.t-flyerApplicationMailer-container-footer-copyright {
  font-size: 10px;
  padding-top: 20px;
  color: #8897a5;
}

.t-mailMessageMailer {
  border: 0;
  margin: 0;
  padding: 0;
  height: 100% !important;
  width: 100% !important;
  color: #112f4b;
  font-family: -apple-system, "BlinkMacSystemFont", "Helvetica Neue", Helvetica, Arial, "Hiragino Sans",
  "Hiragino Kaku Gothic ProN", "Meiryo", "Segoe UI Symbol", sans-serif;
  font-size: 13px;
  line-height: 1.5;
  letter-spacing: 0.02em;
  background-color: #fcfcfc;
}

.t-mailMessageMailer-container {
  max-width: 375px;
  border-spacing: 0;
  background-color: #fff;
  border: 1px solid #ddd;
}

.t-mailMessageMailer-container-main {
  background-color: #f6f8fa;
}

.t-mailMessageMailer-container-footer {
  font-size: 13px;
  color: #708293;
  background-color: #f6f8fa;
  padding: 20px;
}

.t-mailMessageMailer-container-footer-producedBy {
  font-size: 11px;
  color: #8897a5;
}

.t-mailMessageMailer-container-footer-logo {
  padding-top: 30px;
}
