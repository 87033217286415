.m-position-priority-label {
  border-radius: 50px;
  color: #fff;
  display: inline-block;
  font-size: 11px;
  font-weight: bold;
  padding: 0 8px;
  line-height: 20px;
  vertical-align: top;
}
.m-position-priority-label.is-priority-high{
    background-color: #ff3c3c;
  }
.m-position-priority-label.is-priority-low{
    background-color: #41596f;
  }
