.m-tab {
  border-bottom: 1px solid #ddd;
  display: flex;
}

.m-tab-item {
  color: #708293;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
}
.m-tab-item:hover{
    background-color: #f2f2f2;
  }
.m-tab-item.is-active{
    color: #112f4b;
  }
.m-tab-item.is-active > .m-tab-item-body{
      border-bottom: 3px solid #294de6;
    }

.m-tab-item-body {
  font-size: 14px;
  margin: 0 20px;
  padding: 20px 0;
}
