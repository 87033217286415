.fr-box {
  padding-bottom: 55px;
  height: 100%;
}

.fr-box .fr-wrapper {
  height: 100%;
}

.fr-box .fr-wrapper .fr-element.fr-view {
  height: 100%;
}

.fr-box .fr-wrapper .fr-element.fr-view li li {
  list-style-type: circle;
}

.fr-box .fr-wrapper .fr-element.fr-view li li li {
  list-style-type: square;
}

.fr-toolbar .fr-command.fr-btn span {
  color: #fff;
}

.fr-toolbar .fr-command.fr-btn.fr-active span {
  color: #0098f7;
}

.dark-theme.fr-box .fr-line-breaker {
  border-top: 3px solid #8fa3ff;
}

.dark-theme.fr-box .fr-element::selection {
  background-color: #8fa3ff;
}

.dark-theme.fr-box .fr-element *::selection {
  background-color: #8fa3ff;
}

.dark-theme.fr-box .fr-quick-insert a.fr-floating-btn svg.fr-svg {
  fill: #fff;
}

.dark-theme.fr-box .fr-qi-helper {
  display: flex;
}

.dark-theme.fr-box .fr-qi-helper a.fr-btn.fr-floating-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: #444;
}

.dark-theme.fr-tooltip {
  margin-top: 4px;
}

.dark-theme.fr-box a.fr-floating-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #444;
}

.dark-theme.fr-box a.fr-floating-btn:hover {
  background: #363636;
}

.dark-theme.fr-box a.fr-floating-btn svg {
  color: #fff;
}

.dark-theme.fr-toolbar {
  background: #444;
  border-radius: 24px;
}

.dark-theme.fr-popup {
  background: #444;
  color: #fff;
}

.dark-theme.fr-popup .fr-file-upload-layer {
  background: #444;
}

.dark-theme.fr-popup .fr-file-upload-layer:hover {
  background: #363636;
}

.dark-theme.fr-toolbar .fr-btn-grp .fr-btn svg path {
  fill: #fff;
}

.dark-theme.fr-popup .fr-buttons .fr-btn svg path {
  fill: #fff;
}

.dark-theme.fr-popup .fr-buttons.fr-tabs {
  background: #363636;
}

.dark-theme.fr-desktop .fr-command:hover:not(.fr-table-cell),
.dark-theme.fr-desktop .fr-command:focus:not(.fr-table-cell),
.dark-theme.fr-desktop .fr-command.fr-btn-hover:not(.fr-table-cell),
.dark-theme.fr-desktop .fr-command.fr-expanded:not(.fr-table-cell) {
  background: #363636;
}

.dark-theme.fr-toolbar .fr-btn-grp .fr-btn:hover,
.dark-theme.fr-popup .fr-buttons .fr-btn:hover,
.dark-theme.fr-popup .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
.dark-theme.fr-popup .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab,
.dark-theme.fr-modal .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
.dark-theme.fr-modal .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab {
  background: #444;
}
