.react-datepicker {
  width: 100%;
  border: 0;
  border-radius: 0;
  margin-top: 10px;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__header {
  background-color: #fff;
  border-bottom: 0;
  padding-top: 0;
}

.react-datepicker__month {
  margin: 8px 0 20px 0;
}

.react-datepicker__day-name {
  color: #294de6;
  font-size: 14px;
  text-align: center;
  padding: 8px 15px;
  margin: 0;
  width: 46px;
}

.react-datepicker__day {
  border-radius: 0;
  font-size: 14px;
  text-align: center;
  outline: 0;
  padding: 5px 15px;
  margin: 3px 0;
  width: 46px;
}

.react-datepicker__day:hover {
  border-radius: 0;
}

.react-datepicker__day--today {
  font-weight: 400;
}

.react-datepicker__day--keyboard-selected {
  background-color: #fff;
  color: #000;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: #294de6;
  border-radius: 0;
  color: #fff;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: #f0f0f0;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover {
  background-color: #213eb8;
}

/* 選択期間内 開始と終了が同じ日 */
.react-datepicker__day.react-datepicker__day--range-start.react-datepicker__day--range-end.react-datepicker__day--in-range,
  /* 選択期間内 週の最初 かつ 選択期間の最後 */
.react-datepicker__week .react-datepicker__day--in-range:first-of-type.react-datepicker__day--range-end,
  /* 選択期間内 週の最後 かつ 選択期間の最初 */
.react-datepicker__week .react-datepicker__day--in-range:last-of-type.react-datepicker__day--range-start {
  border-radius: 50px;
}

/* 選択期間内 開始日 */
.react-datepicker__day.react-datepicker__day--range-start.react-datepicker__day--in-range,
  /* 選択期間内 週の最初 */
.react-datepicker__week .react-datepicker__day--in-range:first-of-type {
  border-radius: 50px 0 0 50px;
}

/* 選択期間内 終了日 */
.react-datepicker__day.react-datepicker__day--range-end.react-datepicker__day--in-range,
  /* 選択期間内 週の最後 */
.react-datepicker__week .react-datepicker__day--in-range:last-of-type {
  border-radius: 0 50px 50px 0;
}