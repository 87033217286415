.p-slackBotConnectionMailer .o-slackBotConnectionMailer-title{
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  font-size: 18px;
}
.p-slackBotConnectionMailer .o-slackBotConnectionMailer-title b{
  font-weight: bold;
}
.p-slackBotConnectionMailer .o-slackBotConnectionMailer-content{
  padding: 4px 0;
  padding: 30px 0 40px;
}
.p-slackBotConnectionMailer .o-slackBotConnectionMailer-description{
  padding: 4px 0;
  font-size: 16px;
  text-align: left;
}
.p-slackBotConnectionMailer .o-slackBotConnectionMailer-description-help{
  padding: 8px 0;
  font-size: 16px;
  text-align: left;
}
