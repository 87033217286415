.p-prospectShow-mainPane-main-body{
    display: flex;
    margin-top: 30px;
    height: 100%;
  }
.p-prospectShow-mainPane-main-body-firstColumn{
      width: 340px;
      height: 100%;
      flex-shrink: 0;
    }
.p-prospectShow-mainPane-main-body-secondColumn{
      height: 100%;
      width: 100%;
      margin-left: 20px;
    }
.p-prospectShow .t-twoPaneWithSideMainPane-mainPaneContainer-mainPane-side .o-prospectSideSection + .o-prospectSideSection{
      margin-top: 20px;
    }
.p-prospectShow .o-prospectMultiScreenContainer{
    height: 100%;
    margin-left: 20px;
  }
.p-prospectShow .o-prospectMultiScreenContainer > div[data-hypernova-key]{
      height: 100%;
    }
.p-prospectShow .m-prospectJobChangeIntentionPanel{
    margin-top: 20px;
  }
.p-prospectShow .o-prospectProcessStatusButtonContainer-dropdown{
    z-index: 1;
  }
