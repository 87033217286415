.o-prospectCustomPropertyArchivePanelListNavigation {
  align-items: center;
  display: flex;
}

.o-prospectCustomPropertyArchivePanelListNavigation-title {
  font-size: 24px;
}

.o-prospectCustomPropertyArchivePanelListNavigation-backButtonContainer {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.o-prospectCustomPropertyArchivePanelListNavigation-backLink {
  color: #708293;
  font-size: 14px;
  text-decoration: underline;
  margin-right: 20px;
}
.o-prospectCustomPropertyArchivePanelListNavigation-backLink:hover{
    opacity: 0.8;
  }

.o-prospectCustomPropertyArchivePanelListNavigation-paginationContainer {
  margin: 20px 0;
}
.o-prospectCustomPropertyArchivePanelListNavigation-paginationContainer .m-pagenation{
    font-size: 16px;
  }
