.m-landingLogin {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}
.m-landingLogin-heading{
    text-align: center;
  }
.m-landingLogin-heading-image{
      width: 358px;
    }
.m-landingLogin-main{
    font-size: 24px;
    padding: 50px 20px;
    text-align: center;
  }
.m-landingLogin-footer{
    text-align: center;
  }
.m-landingLogin-input-field{
      border: solid 1px #ddd;
      border-radius: 6px;
      font-size: 24px;
      padding: 15px;
      width: 280px;
    }
.m-landingLogin-input-field::placeholder{
        color: #a0acb7;
      }
.m-landingLogin-button{
    background-color: #294de6;
    border-radius: 6px;
    color: #fff;
    font-size: 17px;
    min-width: 220px;
    padding: 20px;
  }
.m-landingLogin-terms-and-privacy{
    font-size: 14px;
    margin-top: 30px;
  }
