.o-flyerPanelListNavigation {
  align-items: center;
  display: flex;
}

.o-flyerPanelListNavigation-title {
  font-size: 24px;
}

.o-flyerPanelListNavigation-newFlyerButtonContainer {
  margin-left: auto;
}

.o-flyerPanelListNavigation-paginationContainer {
  margin: 20px 0;
}
.o-flyerPanelListNavigation-paginationContainer .m-pagenation{
    font-size: 16px;
  }
