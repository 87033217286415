.o-flyerItemPicker-container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 10;
}

.o-flyerItemPicker-dropdown {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.03);
  position: absolute;
  left: 0;
  width: 350px;
  overflow: hidden;
}

.o-flyerItemPicker-dropdownHeader {
  background-color: #fcfcfc;
  border-bottom: 1px solid #ddd;
  padding: 10px;
}

.o-flyerItemPicker-searchContainer {
  position: relative;
}
.o-flyerItemPicker-searchContainer i{
    color: #708293;
    font-size: 20px;
    left: 8px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
.o-flyerItemPicker-searchContainer-searchInput{
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 13px;
    height: 42px;
    outline: none;
    padding: 8px 8px 8px 30px;
    width: 100%;
  }
.o-flyerItemPicker-searchContainer-searchInput:focus{
      border-color: #294de6;
    }

.o-flyerItemPicker-dropdownInner {
  max-height: 350px;
  overflow-y: scroll;
  position: relative;
}

.o-flyerItemPicker-dropdownItem {
  align-items: center;
  color: #112f4b;
  display: flex;
  padding: 0 40px 0 10px;
  position: relative;
  width: 100%;
}
.o-flyerItemPicker-dropdownItem + .o-flyerItemPicker-dropdownItem{
    border-top: 1px solid #ddd;
  }
.o-flyerItemPicker-dropdownItem > i{
    color: #294de6;
    font-size: 18px;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(0, -50%);
  }
.o-flyerItemPicker-dropdownItem:hover{
    background-color: #f2f2f2;
  }

.o-flyerItemPicker-dropdownItemText {
  font-size: 13px;
  padding: 12px 0;
  display: block;
}
.o-flyerItemPicker-dropdownItemText.has-message{
    max-width: calc(100% - 95px - 30px);
  }
.o-flyerItemPicker-dropdownItemText > span{
    display: block;
    line-height: 1.2;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

.o-flyerItemPicker-dropdownItemSubText {
  color: #708293;
  margin-top: 5px;
}

.o-flyerItemPicker-dropdownItemLoader {
  padding: 10px;
}
.o-flyerItemPicker-dropdownItemLoader + .o-flyerItemPicker-dropdownItemLoader{
    border-top: 1px solid #ddd;
  }
