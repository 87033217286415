.o-schedulePanelList-heading{
    color: #708293;
    font-size: 20px;
    font-weight: bold;
  }
.o-schedulePanelList-itemContainer{
    display: flex;
    margin-top: 15px;
  }
.o-schedulePanelList-itemContainer-item{
      border: 1px solid #ddd;
      background-color: #fff;
      border-radius: 6px;
      padding: 15px 20px;
      display: flex;
    }
.o-schedulePanelList-itemContainer-item + .o-schedulePanelList-itemContainer-item{
        margin-left: 20px;
      }
.o-schedulePanelList-itemContainer-item-dateContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
.o-schedulePanelList-itemContainer-item-dateContainer-month{
          color: #708293;
          font-weight: bold;
        }
.o-schedulePanelList-itemContainer-item-dateContainer-day{
          font-size: 16px;
          font-weight: bold;
        }
.o-schedulePanelList-itemContainer-item-prospect{
        margin-left: 15px;
      }
.o-schedulePanelList-itemContainer-item-prospect-name{
          font-weight: bold;
          font-size: 16px;
        }
.o-schedulePanelList-itemContainer-item-prospect-imageContainer{
          margin-top: 5px;
        }
.o-schedulePanelList-itemContainer-item-prospect-imageContainer-image{
            width: 20px;
            height: 20px;
          }
