.p-userShow .p-userShow-body{
    display: flex;
  }
.p-userShow .p-userShow-body-firstColumn{
    flex-shrink: 0;
    width: 340px;
  }
.p-userShow .p-userShow-body-secondColumn{
    margin-left: 20px;
    width: 100%;
  }
