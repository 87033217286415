.o-userActivityListContainer {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: column;
  width: 100%;
}

.o-userActivityListContainer-list {
  list-style: none;
}

.o-userActivityListContainer-list-row {
  border-top: 6px solid #f6f8fa;
  padding: 20px;
}
.o-userActivityListContainer-list-row:first-child{
    border: none;
  }

.o-userActivityListContainer-list-subject {
  align-items: center;
  display: flex;
}
.o-userActivityListContainer-list-subject i{
    align-items: center;
    display: block;
    margin-right: 10px;
    vertical-align: middle;
  }

.o-userActivityListContainer-list-subject-userImageContainer {
  margin-right: 7px;
}
.o-userActivityListContainer-list-subject-userImageContainer img{
    height: 30px;
    width: 30px;
  }

.o-userActivityListContainer-list-subject-main {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.o-userActivityListContainer-list-subject-main-text-icon {
  font-size: 18px;
  margin: 0 5px;
}

.o-userActivityListContainer-list-subject-main-date {
  color: #708293;
  font-size: 12px;
  line-height: 1.2;
  margin-top: 3px;
}

.o-userActivityListContainer-list-prospectContainer {
  align-items: center;
  display: flex;
}

.o-userActivityListContainer-list-prospectImageContainer {
  flex: 0 0 auto;
  height: 45px;
  margin-right: 10px;
  padding: 9px;
  width: 45px;
}

.o-userActivityListContainer-list-prospectName {
  color: #112f4b;
  font-weight: bold;
}

.o-userActivityListContainer-list-body {
  margin-top: 15px;
}
